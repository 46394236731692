import { useEffect } from "react";
import * as queryString from "query-string";

export const GoogleCallback = () => {
  useEffect(() => {
    const data = queryString.parse(window.location.search);
    window.opener.postMessage(JSON.stringify(data), "https://eleven-ginkgo.pages.dev");
    window.close();
  }, []);

  return null;
};

export const DiscordCallback = () => {
  useEffect(() => {
    const data = queryString.parse(window.location.search);
    window.opener.postMessage(JSON.stringify(data), "https://eleven-ginkgo.pages.dev");
    window.close();
  }, []);

  return null;
};

export const GithubCallback = () => {
  useEffect(() => {
    const data = queryString.parse(window.location.search);
    window.opener.postMessage(JSON.stringify(data), "https://eleven-ginkgo.pages.dev");
    window.close();
  }, []);

  return null;
};
