import { BrowserRouter, Route, Switch } from "react-router-dom";
import { WindowParent } from "./components/WindowParent";
import { GoogleCallback, GithubCallback, DiscordCallback } from "./components/WindowChild";
export const App = () => {
  return (
    <div style={{ margin: "0px 20px" }}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={WindowParent} />
          <Route exact path="/auth/google" component={GoogleCallback} />
          <Route exact path="/auth/github" component={GithubCallback} />
          <Route exact path="/auth/discord" component={DiscordCallback} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};
