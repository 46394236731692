import React, { useEffect, useState } from "react";
import {
  GoogleLoginButton,
  GithubLoginButton,
  TelegramLoginButton,
  DiscordLoginButton
} from "react-social-login-buttons";
import SweetAlert from 'sweetalert2-react';

export const WindowParent = () => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?15";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const setSwalState = ({show, title, content}) => {
    setShow(show);
    setTitle(title);
    setContent(content);
  }

  const handler = function (e) {
    if (e.origin !== "https://eleven-ginkgo.pages.dev") return;
    var data, parsed_data;
    try {
      data = JSON.parse(e.data);
    } catch (e) {
      parsed_data = e.data;
    }

    if (typeof data === 'object') {
      parsed_data = Object.keys(data)
        .sort()
        .map(key => `${key} => ${data[key]}`).join('<br>')
    }

    setSwalState({ show: true, title: 'Recieved data', content: parsed_data });
    window.removeEventListener("message", handler, false);
  };

  const authTelegram = () => {
    window.addEventListener("message", handler);
    window.Telegram.Login.auth(
      { bot_id: "786699149", request_access: true },
      (data) => {
        if (!data) {
          console.log("error")
        }
        window.postMessage(JSON.stringify(data), "https://eleven-ginkgo.pages.dev");
      }
    );
  };

  const authGoogle = () => {
    window.addEventListener("message", handler);
    window.open(
      "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=https://eleven-ginkgo.pages.dev/auth/google&scope=profile email&client_id=310703059955-ocms672ir8c4emu5gar5v5f5je4srifh.apps.googleusercontent.com",
      "_blank",
      "width=550,height=470,left=685,top=[object Window],status=0,location=0,menubar=0,toolbar=0"
    );
  };
  
  const authDiscord = () => {
    window.addEventListener("message", handler);
    window.open(
      "https://discord.com/oauth2/authorize?response_type=code&redirect_uri=https://eleven-ginkgo.pages.dev/auth/discord&scope=identify email&client_id=850006024957853796",
      "_blank",
      "width=550,height=470,left=685,top=[object Window],status=0,location=0,menubar=0,toolbar=0"
    );
  };
  
  const authGithub = () => {
    window.addEventListener("message", handler);
    window.open(
      "https://github.com/login/oauth/authorize?response_type=code&redirect_uri=https://eleven-ginkgo.pages.dev/auth/github&client_id=21615929b8b7a8396c2f",
      "_blank",
      "width=550,height=470,left=685,top=[object Window],status=0,location=0,menubar=0,toolbar=0"
    );
  };

  return (
    <div>
      <h1>Hey there.</h1>
      <GoogleLoginButton onClick={authGoogle} />
      <TelegramLoginButton onClick={authTelegram} />
      <DiscordLoginButton onClick={authDiscord} />
      <GithubLoginButton onClick={authGithub} />
      <SweetAlert
        show={show}
        title={title}
        html={content}
        onConfirm={() => setSwalState({ show: false, title: null, content: null })}
      />
    </div>
  );
};
